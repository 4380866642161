export default {
  name: 'notifications',
  data() {
    return {
      notifications: [{
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }, {
        title: 'System Notification',
        date: 'Wed 24 Mar, 2021 13:24:28',
        body: 'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
      }]
    };
  }
};