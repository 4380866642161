var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__notification"
  }, [_c('div', {
    staticClass: "mnotify-card",
    attrs: {
      "id": "profile"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "mnotify-card-body"
  }, [_c('div', {
    staticClass: "__list__container"
  }, [_c('ul', _vm._l(_vm.notifications, function (notification, key) {
    return _c('li', {
      key: key
    }, [_vm._m(1, true), _c('div', {
      staticClass: "__title"
    }, [_c('h3', [_vm._v(_vm._s(notification.title))]), _c('p', [_vm._v(_vm._s(notification.date))])]), _c('div', {
      staticClass: "__body"
    }, [_c('p', [_vm._v(_vm._s(notification.body))])])]);
  }), 0)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify-card-header",
    attrs: {
      "id": "top__header"
    }
  }, [_c('div', {
    staticClass: "__header__text"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text pt-1"
  }, [_vm._v("Notifications")]), _c('div', {
    staticClass: "__badge"
  }, [_vm._v("10")])]), _c('button', {
    staticClass: "clear__all"
  }, [_vm._v("Clear All")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/Setting.svg"),
      "alt": "gear icon for settings"
    }
  })]);
}];
export { render, staticRenderFns };